(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name persons.controller:PersonsCtrl
   *
   * @description
   *
   */
  angular
  .module('neo.home.persons')
  .controller('PersonsCtrl', PersonsCtrl);

  function PersonsCtrl($state, $filter, $stateParams, entities, categories, genders, sports, licenseTypes, EntityTeams, LicenseTeam, $mdToast, $mdDialog, ImportPlayers) {
    var vm = this;
    vm.ctrlName = 'PersonsCtrl';
    vm.hasPermission = true;
    vm.selectedEntity = $stateParams.selectedEntity;
    vm.selectedTeam = $stateParams.selectedTeam;

    vm.data = {
      title: 'PERSONS_LIST',
      notFoundMessage: 'RESULT_NOT_FOUND',
      headers: [
        {id: 'name', name: 'NAME', canOrder: true, orderBy: 'nameOrder'},
        {id: 'surname', name: 'SURNAME', canOrder: true, orderBy: 'surnameOrder'},
         {id: 'secondSurname', name: 'SECOND_SURNAME', canOrder: true},
          {id: 'bornDate', name: 'BIRTH_DATE', canOrder: true,orderBy: 'bornDateOrder'},
        {id: 'personType', name: 'PEOPLE.TYPE', canOrder: true},
        {id: 'category', name: 'CATEGORY', canOrder: true, orderBy: 'categoryOrder', orderDesc: true},
        {id: 'sport', name: 'SPORT', canOrder: true}
      ],
      state: {
        slink: 'home.persons.person.information', params: [
          {key: 'team', value: 'teamId'},
          {key: 'license', value: 'id'}
        ]
      },
      defaultOrderBy: ['nameOrder', 'surnameOrder'],
      filters: [
        {
          label: 'ENTITIES.SELECT',
          showField: 'name',
          filterParam: 'entityId',
          filterField: 'id',
          required: true,
          options: entities,
          selectedItem: undefined,
          eventTrigger: function (params) {
            EntityTeams.query({entityId: params.entityId}, function (data) {
                angular.forEach(data, function (item) {
                  var categoryName = $filter('translate')('CATEGORIES.' + findItemById(categories, item.categoryId).name);
                  var genderName = $filter('translate')(findItemById(genders, item.genderId).name);
                  item.name += ' - ' + categoryName + ' - ' + genderName;
                });
                vm.data.filters[1].options = data;
                if (!_.some(data, function (person) {
                    return vm.data.filters[1].selectedItem && person.id === vm.data.filters[1].selectedItem.id
                  })) {
                  vm.data.filters[1].selectedItem = null;
                }
              }
            );
          }
        },
        {
          label: 'TEAMS',
          showField: 'name',
          filterParam: 'teamId',
          filterField: 'id',
          required: true,
          selectedItem: undefined,
          dependsOn: 0
        }
      ],
      filterValues: {
        ENTITIES: vm.selectedEntity,
        TEAMS: vm.selectedTeam
      },
      filterFunction: function (data) {
        var persons = [];
        angular.forEach(data, function (license) {
          var category = findItemById(categories, license.categoryId);
          var newPerson = {
            id: license.id,
            teamId: license.teamId,
            name: license.person.name,
            nameOrder: $filter('lowercase')(license.person.name),
            surname: license.person.surname,
              surnameOrder: $filter('lowercase')(license.person.surname),
            secondSurname: license.person.secondSurname,
               bornDate:  ('0'+license.person.bornDate[2]).slice(-2)+'/'+('0'+license.person.bornDate[1]).slice(-2)+'/'+ license.person.bornDate[0],
                 bornDateOrder: license.person.bornDate,
            category: $filter('translate')('CATEGORIES.' + category.name),
          
            categoryOrder: category.startYear,
            sport: $filter('translate')('SPORTS.' + findItemById(sports, vm.data.filters[1].selectedItem.sportId).name),
            personType: $filter('translate')('PERSON_TYPES.' + findItemById(licenseTypes, license.licenceTypeId).name)
          };
          persons.push(newPerson);
        });
        return persons;
      },
      filterPromise: function (params) {
        return LicenseTeam.query({teamId: params.teamId}).$promise;
      }
    };


    var hasSelectedItem = function (filter) {
      return angular.isDefined(filter.selectedItem) && filter.selectedItem !== null;
    };

    vm.getEntity = function () {
      if (hasSelectedItem(vm.data.filters[0])) {
        return vm.data.filters[0].selectedItem;
      } else {
        return undefined;
      }
    };

    vm.getTeam = function () {
      if (hasSelectedItem(vm.data.filters[1])) {
        return vm.data.filters[1].selectedItem;
      } else {
        return undefined;
      }
    };

    vm.importAthletes = function () {
      var entityId = vm.getEntity();
      if (angular.isUndefined(entityId)) {
        $mdToast.show(
          $mdToast.simple()
            .textContent($filter('translate')('SELECT_ENTITY'))
          .position('bottom left')
          .hideDelay(3000)
        );
        return;
      }
      var teamId = vm.getTeam();
      if (angular.isUndefined(teamId)) {
        $mdToast.show(
          $mdToast.simple()
            .textContent($filter('translate')('SELECT_TEAM'))
          .position('bottom left')
          .hideDelay(3000)
        );
        return;
      }

      ImportPlayers.query({teamId: vm.getTeam()}, function (athletesToImport) {
        $mdDialog.show({
          controller: 'ImportAthletesDialogControllerCtrl',
          templateUrl: 'home/persons/importAthletesDialog.tmpl.html',
          parent: angular.element(document.body),
          clickOutsideToClose: false,
          locals: {
            athletesToImport: athletesToImport
          }
        })
        .then(function (importList) {
          if(importList.length > 0){
            ImportPlayers.save({teamId: vm.getTeam()}, importList,
            function () {
              $mdToast.show(
                $mdToast.simple()
                  .textContent($filter('translate')('PEOPLE.CORRECTLY_IMPORTED'))
                .position('bottom left')
                  .hideDelay(3000)
              );
              $state.go('home.persons', {}, {reload: true});
            },
            function (error) {
              var message = '';
              if(error.status===404){
                message = "TEAM_NOT_FOUND";
              }
              else if (error.status===400){
                message = "NOT_VALID_MODEL";
              }
              $mdToast.show(
                $mdToast.simple()
                .textContent($filter('translate')(message))
                .position('bottom left')
                .hideDelay(3000))
            })
          }
        });
      })

    };

    vm.buttons = {
      dial: true,
      icon: 'more_vert',
      tooltip: 'Accions',
      actions: []
    };

    vm.buttons.actions.push({
      changeState: true,
      stateToChange: "home.persons.newPerson",
      paramsToChange: {
        selectedEntity: vm.getEntity,
        selectedTeam: vm.getTeam
      },
      buttonImage: "images/ic_person_add_black_48px.svg",
      tooltip: $filter('translate')('PEOPLE.NEW_PERSON')
    });

    vm.buttons.actions.push({
      changeState: false,
      execute: vm.importAthletes,
      buttonImage: "images/ic_import_export_black_48px.svg",
      tooltip: $filter('translate')('PEOPLE.IMPORT_ATHLETES')
    });

    var findItemById = function (collection, itemId) {
      return _.find(collection, function (i) {
        return i.id === itemId;
      });
    };
  }
}());
